<script>
// Extensions
import View from "@/views/public/View";

// Mixins
import LoadSections from "@/mixins/loadSections";

export default {
  name: "FourOhFour",

  metaInfo: { title: "Oops! Pagina No Encontrada" },

  extends: View,

  mixins: [LoadSections(["public-404", "layout-footer-basic"])],
};
</script>
